import styled from '@emotion/styled'
import { css } from '@emotion/react'

import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'

export const JumbotronContainer = styled(Jumbotron)`
    padding: 0;
    margin: 0;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    position: relative;
    overflow: hidden;
    border-radius: 0;
`

export const staticImageStyle = css`
    grid-area: 1/1/2/2;
`

export const Gradient = styled.div`
    background: rgb(104, 104, 104);
    background: linear-gradient(0deg, rgba(104, 104, 104, 1) 30%, rgba(16, 16, 16, 1) 60%);
    grid-area: 1/1/2/2;
    opacity: 40%;
`

export const JumbotronContent = styled(Container)`
    color: white;
    grid-area: 1/1/2/2;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 8.75rem; /* 140px */
    padding-bottom: 8.75rem; /* 140px */
`

export const HeadingContainer = styled.div``

export const UnderlinedHeading = styled.h1`
    display: inline-flex;
    flex-direction: column;
    font-family: 'Full Sans SC 70', sans-serif;
    font-size: 1.875rem;
    margin-bottom: 1.875rem;
    text-transform: uppercase;

    &::after {
        content: '';
        display: block;
        width: 0.625rem;
        height: 0.625rem;
        background-color: white;
        animation: increase 1s;
        animation-fill-mode: forwards;
        animation-delay: 1s;
    }

    @media (max-width: 350px) {
        font-size: 1.25rem; /* 20px */
    }
`

export const H1 = styled.h1`
    font-size: 5.25rem; /* 84px */
    margin-bottom: 3.75rem;

    @media (max-width: 465px) {
        font-size: 3.75rem; /* 60px */
        margin-bottom: 1.875rem; /* 30px */
    }

    @media (max-width: 350px) {
        font-size: 3.125rem; /* 50px */
    }
`

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 465px) {
        flex-direction: column-reverse;
    }
`
