import styled from '@emotion/styled'

import { Nav } from 'react-bootstrap'

export const NavContainer = styled(Nav)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;
    top: 7.5rem; /* 100px */
    bottom: 1.25rem;
    left: 1.25rem; /* 20px */
    right: 1.25rem;
    background: white;
    visibility: hidden;
    transform: translate3d(0, 102%, 0);
    transition: transform 0.7s cubic-bezier(0.4, 0, 0, 1) 0.5s, visibility 0s 1.2s;
    box-shadow: 0rem 1.25rem 0rem 1.25rem var(--light-gray);

    &.open {
        visibility: visible;
        transform: translateZ(0);
        transition-delay: 0s;
    }
`

export const NavLink = styled(Nav.Link)`
    width: 100%;
    text-align: center;
    padding: 1.625rem 0;
    font-size: 1.625rem;
    font-family: 'Full Sans SC 30', Arial, sans-serif;
    color: var(--black);
    transition: all 0.25s cubic-bezier(0.4, 0, 0, 1);

    &:hover {
        color: white;
        background-color: var(--black);
    }
`
