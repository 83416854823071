import styled from '@emotion/styled'

import SectionLayout from '../section-layout/SectionLayout'

export const SectionContainer = styled(SectionLayout)`
    background-color: transparent;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Full Sans SC 50', Arial, sans-serif;

    @media (max-width: 768px) {
        height: auto !important;
    }
`
