import styled from '@emotion/styled'
import { css } from '@emotion/react'

import SectionLayout from '../section-layout/SectionLayout'
import Container from 'react-bootstrap/Container'

export const SectionContainer = styled(SectionLayout)`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
`

export const Gallery = styled.div`
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin-bottom: 2.5rem; /* 40px */

    @media (max-width: 576px) {
        display: block;
    }
`

export const imageStyle = css`
    cursor: pointer;

    &.pos-1 {
        grid-area: 3 / 2 / 6 / 4;
    }

    &.pos-2 {
        grid-area: 8 / 2 / 11 / 4;
    }

    &.pos-3 {
        grid-area: 1 / 5 / 4 / 7;
    }

    &.pos-4 {
        grid-area: 6 / 5 / 9 / 7;
    }

    &.pos-5 {
        grid-area: 2 / 8 / 5 / 10;
    }

    &.pos-6 {
        grid-area: 7 / 8 / 10 / 10;
    }

    @media (max-width: 576px) {
        margin-bottom: 1.25rem;
    }
`
