import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'

import Carousel from 'react-bootstrap/Carousel'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ImageCarousel = ({ collection }) => {
    return (
        <Carousel>
            {collection.map(({ node }) => {
                const image = getImage(node)

                return (
                    <Carousel.Item key={shortid.generate()}>
                        <GatsbyImage image={image} alt="" className="d-block w-100" />
                    </Carousel.Item>
                )
            })}
        </Carousel>
    )
}

ImageCarousel.propTypes = {
    collection: PropTypes.array.isRequired,
}

export default ImageCarousel
