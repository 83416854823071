import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import GalleryContext from '../../contexts/gallery/gallery.context'

import { SectionContainer, Title, Gallery, imageStyle } from './gallerySectionStyles'
import { StaticImage } from 'gatsby-plugin-image'
import Button from '../button/Button'

const GallerySection = ({ className, ...props }) => {
    const { toggle } = useContext(GalleryContext)

    const openGallery = () => toggle('house')

    return (
        <SectionContainer {...props} title="Galéria" className={`${className} p-60`}>
            <Gallery>
                <StaticImage
                    src="../../images/gallery/0994.jpg"
                    alt=""
                    placeholder="tracedSVG"
                    className="pos-1"
                    css={imageStyle}
                    onClick={openGallery}
                />
                <StaticImage
                    src="../../images/gallery/2889.jpg"
                    alt=""
                    placeholder="tracedSVG"
                    className="pos-2"
                    css={imageStyle}
                    onClick={openGallery}
                />
                <StaticImage
                    src="../../images/gallery/2779.jpg"
                    alt=""
                    placeholder="tracedSVG"
                    className="pos-3"
                    css={imageStyle}
                    onClick={openGallery}
                />
                <StaticImage
                    src="../../images/gallery/2734.jpg"
                    alt=""
                    placeholder="tracedSVG"
                    className="pos-4"
                    css={imageStyle}
                    onClick={openGallery}
                />
                <StaticImage
                    src="../../images/gallery/2686.jpg"
                    alt=""
                    placeholder="tracedSVG"
                    className="pos-5"
                    css={imageStyle}
                    onClick={openGallery}
                />
                <StaticImage
                    src="../../images/gallery/2708.jpg"
                    alt=""
                    placeholder="tracedSVG"
                    className="pos-6"
                    css={imageStyle}
                    onClick={openGallery}
                />
            </Gallery>
            <Button className="color-brown btn-empty" onClick={openGallery}>
                további képek
            </Button>
        </SectionContainer>
    )
}

GallerySection.propTypes = {
    className: PropTypes.string,
}

export default GallerySection
