import React from 'react'
import PropTypes from 'prop-types'
import { moneyFormatter } from '../../utils/helper'

import { CardContainer, CardHeader, CardTitle, Title, PriceTag, CardBody } from './priceStyles'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from '../button/Button'
import { Link } from 'gatsby'

import PriceTagBg from '../../images/svg/price-tag.svg'

const Price = ({ price: { title, desc, huf, eur, priceInfo } }) => {
    const priceTag = huf ? moneyFormatter(huf) : 'kérjük érdeklődjön'

    return (
        <Col className="mb-xs-5 mb-sm-5 mb-lg-0">
            <CardContainer className="border-0">
                <CardHeader>
                    <PriceTagBg />
                    <CardTitle>
                        <Title>{title}</Title>
                        <PriceTag>
                            {priceTag} {(huf > 0 || eur > 0) && <small>-tól</small>}
                        </PriceTag>
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    <Card.Text className="text-center my-5">{desc}</Card.Text>
                    <Button as={Link} to="/#kapcsolat" className="color-brown btn-empty btn-sm w-100 text-center">
                        érdeklődöm
                    </Button>
                </CardBody>
            </CardContainer>
        </Col>
    )
}

Price.propTypes = {
    price: PropTypes.object.isRequired,
}

export default Price
