import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import ImageCarousel from '../image-carousel/ImageCarousel'

const GalleryThree = () => {
    const query = graphql`
        query RoomThreeImagesQuery {
            allFile(
                filter: {
                    extension: { regex: "/(jpg)|(jpeg)|(png)/" }
                    sourceInstanceName: { eq: "gallery" }
                    relativeDirectory: { eq: "room-3" }
                }
                sort: { order: ASC, fields: name }
            ) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(width: 1920, formats: [AUTO, WEBP], placeholder: BLURRED)
                        }
                    }
                }
            }
        }
    `

    const renderCarousel = (collection) => <ImageCarousel collection={collection} />

    return <StaticQuery query={query} render={({ allFile: { edges } }) => renderCarousel(edges)} />
}

export default GalleryThree
