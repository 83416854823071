import React, { useState, useEffect } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import apiClient from '../utils/apiClient'

import Seo from '../components/seo/Seo'

import NavbarContext from '../contexts/navbar/navbar.context'
import GalleryContext from '../contexts/gallery/gallery.context'

import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import BackToTop from '../components/back-to-top/BackToTop'
import MobileNav from '../components/mobile-nav/MobileNav'
import HeroSection from '../components/hero-section/HeroSection'
import GuestHouseSection from '../components/guest-house-section/GuestHouseSection'
import RoomsSection from '../components/rooms-section/RoomsSection'
import PriceSection from '../components/price-section/PriceSection'
import GallerySection from '../components/gallery-section/GallerySection'
import ContactSection from '../components/contact-section/ContactSection'
import VideoSection from '../components/video-section/VideoSection'
import GoogleMaps from '../components/google-maps/GoogleMaps'
import GalleryModal from '../components/gallery-modal/GalleryModal'

import '../scss/app.scss'

// TODO: reservationbuttons restore!

const IndexPage = () => {
    const [hidden, setHidden] = useState(true)
    const [open, setOpen] = useState(false)
    const [currentGallery, setCurrentGallery] = useState('')

    useEffect(async () => {
        await apiClient.get(`${process.env.API_DOMAIN_URL}sanctum/csrf-cookie`)
    }, [])

    const toggleHidden = () => {
        document.querySelector('body').classList.toggle('nav-open', hidden)
        setHidden(!hidden)
    }

    const toggle = (gallery) => {
        document.querySelector('body').classList.toggle('nav-open', !open)
        setCurrentGallery(!open ? gallery : '')
        setOpen(!open)
    }

    return (
        <div id="wrapper">
            <Seo title="Söjtöri Öreghegyi Vendégház" />
            <NavbarContext.Provider value={{ hidden, toggleHidden }}>
                <Header />
                <MobileNav />
            </NavbarContext.Provider>
            <main>
                <GalleryContext.Provider value={{ currentGallery, open, toggle }}>
                    <HeroSection className="introduction" />
                    <GuestHouseSection id="vendeghaz" className="guest-house h-lg-auto" />
                    <VideoSection id="video" className="video" />
                    <RoomsSection id="szobak" className="rooms" />
                    <PriceSection id="arak" className="prices" />
                    <GallerySection id="galeria" className="gallery" />
                    <GoogleReCaptchaProvider
                        reCaptchaKey={process.env.GOOGLE_RECAPTCHA_API_KEY}
                        language="hu"
                        scriptProps={{
                            async: true,
                            defer: true,
                            appendTo: 'head',
                        }}
                    >
                        <ContactSection id="kapcsolat" className="contact" />
                    </GoogleReCaptchaProvider>
                    <GoogleMaps />
                    <GalleryModal />
                </GalleryContext.Provider>
            </main>
            <Footer />
            <BackToTop to="/" />
        </div>
    )
}

export default IndexPage
