import React from 'react'
import PropTypes from 'prop-types'

import { ButtonContainer, ButtonContent } from './buttonStyles'

const Button = ({ children, ...props }) => {
    return (
        <ButtonContainer {...props}>
            <ButtonContent>{children}</ButtonContent>
        </ButtonContainer>
    )
}

Button.propTypes = {
    children: PropTypes.node.isRequired,
    as: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
        PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func })])
        ),
    ]),
    to: PropTypes.string,
    className: PropTypes.string,
}

export default Button
