import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { ContactLabel, ContactValue, ContactValueLink } from './contactInfoStyles'

const ContactInfo = () => {
    const { allDataJson } = useStaticQuery(
        graphql`
            query InfoQuery {
                allDataJson {
                    nodes {
                        siteInfos {
                            country
                            email
                            phone
                            street
                            town
                        }
                    }
                }
            }
        `
    )

    const { phone, email, street, town, country } = allDataJson.nodes[0].siteInfos

    return (
        <div className="text-sm-center text-lg-left">
            <ContactLabel>Telefon</ContactLabel>
            <ContactValue>
                <ContactValueLink href={`tel:${phone.replace('-', '')}`}>{phone}</ContactValueLink>
            </ContactValue>
            <ContactLabel>Email</ContactLabel>
            <ContactValue>
                <ContactValueLink href={`mailto:${email}`}>{email}</ContactValueLink>
            </ContactValue>
            <ContactLabel>Cím</ContactLabel>
            <ContactValue>{street}</ContactValue>
            <ContactValue>{town}</ContactValue>
            <ContactValue>{country}</ContactValue>
        </div>
    )
}

export default ContactInfo
