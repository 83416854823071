import React from 'react'
import PropTypes from 'prop-types'

import SectionLayout from '../section-layout/SectionLayout'

const VideoSection = (props) => {
    return (
        <SectionLayout {...props}>
            <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/MOAplF1zTbQ?controls=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </SectionLayout>
    )
}

VideoSection.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
}

export default VideoSection
