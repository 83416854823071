import styled from '@emotion/styled'

import Card from 'react-bootstrap/Card'

export const CardContainer = styled(Card)`
    box-shadow: 0px 0px 15px 0px rgb(114 111 106 / 16%);
    color: var(--brown);
    height: 100%;
`

export const CardHeader = styled(Card.Header)`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding: 0;
    background: none;
    border: none;

    svg {
        grid-area: 1 / 1 / 2 / 2;
        width: 100%;
        height: auto;
    }
`

export const CardTitle = styled(Card.Title)`
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Full Sans SC 90', Arial, sans-serif;
    text-transform: uppercase;
    font-size: 1.5rem; /* 24px */
`

export const Title = styled.span`
    margin-bottom: 1.25rem; /* 20px */
`

export const PriceTag = styled.span`
    small {
        font-size: 70%;
    }
`

export const CardBody = styled(Card.Body)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
