import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { SectionContainer, IntroductionCard, Card, CardTitle, CardText, imagesStyle } from './guestHouseSectionStyles'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from '../button/Button'
import { Link } from 'gatsby'

const GuestHouseSection = (props) => {
    const { allDataJson } = useStaticQuery(
        graphql`
            query {
                allDataJson {
                    nodes {
                        houseDesc
                    }
                }
            }
        `
    )

    const houseDesc = allDataJson.nodes[0].houseDesc

    return (
        <SectionContainer {...props}>
            <StaticImage src="../../images/room.jpg" alt="" css={imagesStyle} breakpoints={[576, 768, 992, 1200]} placeholder="tracedSVG" />
            <IntroductionCard>
                <Row>
                    <Col xl={7} lg={6} md={12}>
                        <Card>
                            <CardTitle>Vendégház</CardTitle>
                            <CardText>{houseDesc}</CardText>
                            <Button as={Link} to="/#szobak" className="color-brown btn-empty">
                                szobák
                            </Button>
                        </Card>
                    </Col>
                </Row>
            </IntroductionCard>
        </SectionContainer>
    )
}

export default GuestHouseSection
