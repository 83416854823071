import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import shortid from 'shortid'
import { isOdd } from '../../utils/helper'

import Room from '../room/Room'

const RoomsSection = (props) => {
    const { allDataJson } = useStaticQuery(
        graphql`
            query RoomsQuery {
                allDataJson {
                    nodes {
                        rooms {
                            id
                            title
                            desc
                            services
                            thumbnail {
                                childImageSharp {
                                    gatsbyImageData(width: 1000, placeholder: TRACED_SVG, formats: [AUTO, WEBP])
                                }
                            }
                            gallery
                        }
                    }
                }
            }
        `
    )

    const rooms = allDataJson.nodes[0].rooms

    return rooms.map((room, index) => <Room key={shortid.generate()} room={room} {...props} isTextRight={isOdd(index)} />)
}

RoomsSection.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
}

export default RoomsSection
