import React from 'react'
import PropTypes from 'prop-types'

import { SectionContainer, SectionHeader, Title } from './sectionLayoutStyles'

import Abstract from '../../images/svg/abstract.svg'

const SectionLayout = ({ children, title, ...props }) => {
    return (
        <SectionContainer {...props}>
            {title && (
                <SectionHeader>
                    <Title>{title}</Title>
                    <Abstract />
                </SectionHeader>
            )}
            {children}
        </SectionContainer>
    )
}

SectionLayout.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
}

export default SectionLayout
