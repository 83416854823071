import styled from '@emotion/styled'

import { Link } from 'gatsby'

export const ScrollTextLetter = styled.span`
    position: relative;
    display: inline-block;
    vertical-align: top;

    &.letter-1 {
        transition: 0.4s opacity 0.3s, 0.4s transform 0.3s;
    }

    &.letter-2 {
        transition: 0.4s opacity 0.35s, 0.4s transform 0.35s;
    }

    &.letter-3 {
        transition: 0.4s opacity 0.4s, 0.4s transform 0.4s;
    }

    &.letter-4 {
        transition: 0.4s opacity 0.45s, 0.4s transform 0.45s;
    }

    &.letter-5 {
        transition: 0.4s opacity 0.5s, 0.4s transform 0.5s;
    }

    &.letter-6 {
        transition: 0.4s opacity 0.55s, 0.4s transform 0.55s;
    }
`

export const ScrollText = styled.div`
    position: relative;
    display: block;
    font-size: 0.626rem;
    font-weight: 700;
    color: white;
    letter-spacing: 0.188em;
    text-transform: uppercase;
    transition: 0.6s transform cubic-bezier(0.19, 1, 0.22, 1);
    transform: translateX(-0.625rem);

    &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: -2.5rem;
        width: 5rem;
        content: '';
    }

    &.text-2 {
        position: absolute;
        top: 0;
        left: 0;

        ${ScrollTextLetter} {
            opacity: 0;

            &.letter-1 {
                transition: 0.4s opacity 0s, 0.4s transform 0s;
                transform: translateX(-0.9375rem);
            }

            &.letter-2 {
                transition: 0.4s opacity 0.05s, 0.4s transform 0.05s;
                transform: translateX(-0.8125rem);
            }

            &.letter-3 {
                transition: 0.4s opacity 0.1s, 0.4s transform 0.1s;
                transform: translateX(-0.6875rem);
            }

            &.letter-4 {
                transition: 0.4s opacity 0.15s, 0.4s transform 0.15s;
                transform: translateX(-0.5625rem);
            }

            &.letter-5 {
                transition: 0.4s opacity 0.2s, 0.4s transform 0.2s;
                transform: translateX(-0.4375rem);
            }

            &.letter-6 {
                transition: 0.4s opacity 0, 25s, 0.4s transform 0, 25s;
                transform: translateX(-0.3125rem);
            }
        }
    }
`

export const Circle = styled(Link)`
    position: absolute;
    text-align: center;
    bottom: 7.25rem;
    left: 50%;
    transform: rotate(-90deg);
    cursor: pointer;
    display: inline-block;
    transition: 0.6s opacity cubic-bezier(0.19, 1, 0.22, 1);

    &::before {
        position: absolute;
        bottom: 0;
        left: -2.6875rem;
        background-color: transparent;
        transform: scale(0);
        width: 1.125rem;
        height: 1.125rem;
        border-radius: 50%;
        content: '';
        transform: scale(0.6);
        transition: 1s background-color, 1s transform cubic-bezier(0.19, 1, 0.22, 1);
    }

    &::after {
        position: absolute;
        bottom: 0.125rem;
        left: -2.5625rem;
        width: 0.875rem;
        height: 0.875rem;
        border: 0.125rem solid white;
        border-radius: 50%;
        content: '';
        transition: 1s border-width, 1s transform cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:hover {
        &::before {
            transform: scale(1);
            background-color: white;
        }

        &::after {
            transform: scale(0);
        }

        ${ScrollText} {
            transition: 1s transform cubic-bezier(0.19, 1, 0.22, 1);

            ${ScrollTextLetter} {
                opacity: 0;
                transform: translateX(0.3125rem);

                &.letter-1 {
                    transition: 0.4s opacity 0.25s, 0.4s transform 0.25s;
                    transform: translateX(0.3125rem);
                }

                &.letter-2 {
                    transition: 0.4s opacity 0.2s, 0.4s transform 0.2s;
                    transform: translateX(0.4375rem);
                }

                &.letter-3 {
                    transition: 0.4s opacity 0.15s, 0.4s transform 0.15s;
                    transform: translateX(0.5625rem);
                }

                &.letter-4 {
                    transition: 0.4s opacity 0.1s, 0.4s transform 0.1s;
                    transform: translateX(0.6875rem);
                }

                &.letter-5 {
                    transition: 0.4s opacity 0.05s, 0.4s transform 0.05s;
                    transform: translateX(0.8125rem);
                }

                &.letter-6 {
                    transition: 0.4s opacity 0s, 0.4s transform 0s;
                    transform: translateX(0.9375rem);
                }
            }

            &.text-2 {
                ${ScrollTextLetter} {
                    opacity: 1;
                    transform: translateX(0);

                    &.letter-1 {
                        transition: 0.4s opacity 0.55s, 0.4s transform 0.55s;
                    }

                    &.letter-2 {
                        transition: 0.4s opacity 0.5s, 0.4s transform 0.5s;
                    }

                    &.letter-3 {
                        transition: 0.4s opacity 0.45s, 0.4s transform 0.45s;
                    }

                    &.letter-4 {
                        transition: 0.4s opacity 0.4s, 0.4s transform 0.4s;
                    }

                    &.letter-5 {
                        transition: 0.4s opacity 0.35s, 0.4s transform 0.35s;
                    }

                    &.letter-6 {
                        transition: 0.4s opacity 0.3s, 0.4s transform 0.3s;
                    }
                }
            }
        }
    }

    @media (max-width: 465px) {
        transform: rotate(0deg);
        bottom: 1.25rem; /* 20px */
    }
`
