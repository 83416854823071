import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import SectionLayout from '../section-layout/SectionLayout'
import { StaticImage } from 'gatsby-plugin-image'
import * as HeroStyle from './heroSectionStyles'
import { Link } from 'gatsby'
import Button from '../button/Button'
import ScrollDown from '../scroll-down/ScrollDown'

const HeroSection = (props) => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        mainTitle
                        place
                    }
                }
            }
        `
    )

    return (
        <SectionLayout {...props}>
            <HeroStyle.JumbotronContainer>
                <StaticImage
                    src="../../images/jumbotronCrop.jpg"
                    alt={site.siteMetadata?.mainTitle}
                    layout="fullWidth"
                    breakpoints={[465, 576, 768, 992, 1200]}
                    css={HeroStyle.staticImageStyle}
                    placeholder="tracedSVG"
                />
                <HeroStyle.Gradient />
                <HeroStyle.JumbotronContent>
                    <HeroStyle.HeadingContainer>
                        <HeroStyle.UnderlinedHeading>{site.siteMetadata?.place}</HeroStyle.UnderlinedHeading>
                        <HeroStyle.H1>{site.siteMetadata?.mainTitle}</HeroStyle.H1>
                    </HeroStyle.HeadingContainer>
                    <HeroStyle.ButtonContainer>
                        <Button as={Link} to="#vendeghaz" className="btn-empty btn-blur mr-4">
                            Nézz körül
                        </Button>
                        <Button as={Link} to="/foglalas" className="text-black ml-4">
                            Foglalj most
                        </Button>
                    </HeroStyle.ButtonContainer>
                </HeroStyle.JumbotronContent>
                <ScrollDown />
            </HeroStyle.JumbotronContainer>
        </SectionLayout>
    )
}

HeroSection.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
}

export default HeroSection
