import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import { SectionContainer, Info } from './priceSectionStyles'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Price from '../price/Price'

const PriceSection = ({ className, ...props }) => {
    const { allDataJson } = useStaticQuery(
        graphql`
            query PricesQuery {
                allDataJson {
                    nodes {
                        prices {
                            desc
                            eur
                            huf
                            id
                            priceInfo
                            title
                        }
                        siteInfos {
                            bankAccountNum
                        }
                    }
                }
            }
        `
    )

    const {
        prices,
        siteInfos: { bankAccountNum },
    } = allDataJson.nodes[0]

    return (
        <SectionContainer {...props} className={`${className} p-60`}>
            <Container>
                <Row xs={1} lg={3}>
                    {prices.map(({ id, ...price }) => (
                        <Price key={id} price={price} />
                    ))}
                </Row>
                <Info>
                    A fizetés lehetséges Ft-ban vagy EUR-ban, bankszámláról előre utalással ({bankAccountNum}) vagy OTP SZÉP kártya
                    bankfiókból előre utalással. Az árak tájékoztató jellegűek, az árváltoztatás jogát fenntartjuk. Kedvezményes
                    lehetőségeink után érdeklődjön a megadott elérhetőségeken. Kellemes időtöltést és jó pihenést kívánunk!
                </Info>
            </Container>
        </SectionContainer>
    )
}

PriceSection.propTypes = {
    className: PropTypes.string,
}

export default PriceSection
