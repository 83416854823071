import styled from '@emotion/styled'

import Button from 'react-bootstrap/Button'

export const ButtonContent = styled.span`
    background-color: white;
    color: var(--black);
    padding: 0.625rem 3.75rem; /* 10px 60px */
    display: flex;
    align-items: center;

    @media (max-width: 576px) {
        padding-left: 2.125rem;
        padding-right: 2.125rem;
    }
`

export const ButtonContainer = styled(Button)`
    position: relative;
    border: 0.1875rem solid;
    border-color: white;
    border-radius: 0;
    background-color: transparent;
    margin: 0;
    padding: 0.1875rem;
    font-family: 'Full Sans SC 70', sans-serif;
    font-size: 1.375rem;
    text-transform: uppercase;
    backdrop-filter: none;

    &:active,
    &:focus,
    &:hover {
        border-color: white !important;
        background-color: white !important;
        outline: none !important;
        box-shadow: none !important;

        ${ButtonContent} {
            border-color: white !important;
            color: var(--black) !important;
        }
    }

    &.text-center {
        ${ButtonContent} {
            justify-content: center;
        }
    }

    &.btn-sm {
        font-size: 1rem; /* 16px */

        ${ButtonContent} {
            padding: 0.625rem 1.875rem; /* 10px 30px */
        }
    }

    &.btn-empty {
        ${ButtonContent} {
            background-color: transparent !important;
            color: white;
        }
    }

    &.btn-blur {
        backdrop-filter: blur(3px);
    }

    &.color-brown {
        border-color: var(--brown);

        &:not(.btn-empty) {
            ${ButtonContent} {
                color: white;
            }
        }

        ${ButtonContent} {
            background-color: var(--brown);
            color: var(--brown);
        }

        &:active,
        &:focus,
        &:hover {
            border-color: var(--brown) !important;
            background-color: var(--brown) !important;
            outline: none !important;
            box-shadow: none !important;

            ${ButtonContent} {
                border-color: var(--brown) !important;
                color: white !important;
            }
        }
    }

    &.btn-font-sm {
        font-size: 1rem; /* 16px */
    }

    @media (max-width: 576px) {
        font-size: 1.25rem;
    }

    @media (max-width: 465px) {
        margin: 0 0 1.875rem 0 !important;
    }
`
