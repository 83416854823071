import styled from '@emotion/styled'
import { css } from '@emotion/react'

import SectionLayout from '../section-layout/SectionLayout'
import Container from 'react-bootstrap/Container'

export const SectionContainer = styled(SectionLayout)`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
`

export const IntroductionCard = styled(Container)`
    grid-area: 1/1/2/3;
    display: flex;
    align-items: center;
    color: var(--brown);

    @media (max-width: 768px) {
        grid-area: 2/1/3/2;
    }
`

export const Card = styled.div`
    background-color: white;
    box-shadow: 0px 0px 15px 0px rgba(114, 111, 106, 0.16);
    padding: 3.75rem; /* 60px */
    text-align: center;

    @media (max-width: 992px) {
        background-color: transparent;
        box-shadow: none;
        padding: 3.75rem 1.875rem; /* 60px 30px */
    }

    @media (max-width: 768px) {
        box-shadow: none;
    }

    @media (max-width: 375px) {
        padding: 1.875rem 0;
    }
`

export const CardTitle = styled.h1`
    font-family: 'Full Sans SC 90', Arial, sans-serif;
    font-size: 2.625rem; /* 42px */
    margin-bottom: 1.875rem; /* 30px */
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 375px) {
        font-size: 1.875rem; /* 30px */
    }
`

export const CardText = styled.p`
    font-size: 1.125rem; /* 20px */
    text-align: justify;
    margin-bottom: 1.875rem; /* 30px */
    line-height: 1.7;

    @media (max-width: 375px) {
        font-size: 1rem; /* 16px */
    }
`

export const imagesStyle = css`
    grid-area: 1/2/2/3;

    @media (max-width: 768px) {
        grid-area: 1/1/2/2;
        max-height: 28.125rem;
    }
`
