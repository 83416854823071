import styled from '@emotion/styled'
import { css } from '@emotion/react'

import SectionLayout from '../section-layout/SectionLayout'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export const staticImageStyle = css`
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 50%;
    max-height: 21.875rem;

    @media (max-width: 768px) {
        position: relative;
        bottom: 0;
    }
`

export const TextRow = styled(Row)`
    color: var(--brown);
    position: relative;

    @media (max-width: 992px) {
        text-align: center !important;
    }
`

export const RoomContent = styled(Container)`
    position: relative;
    background-color: var(--beige);
    padding-top: 1.875rem; /* 30px */
    padding-bottom: 1.875rem; /* 30px */
    color: var(--brown);

    &.align-left {
        ${TextRow} {
            text-align: left;
        }
    }

    &.align-right {
        ${TextRow} {
            text-align: right;
        }
    }
`

export const RoomNumber = styled.span`
    font-weight: bold;
    margin-bottom: 1.875rem; /* 30px */
    text-transform: uppercase;
    font-family: 'Full Sans SC 90', Arial, sans-serif;

    @media (max-width: 768px) {
        text-align: center !important;
    }

    @media (max-width: 375px) {
        font-size: 1.875rem;
    }
`

export const RoomDescription = styled.p`
    margin: 1.875rem 0 0 0;
    text-align: justify;

    @media (max-width: 992px) {
        font-size: 1.125rem;
    }

    @media (max-width: 375px) {
        font-size: 1rem;
    }
`

export const Separator = styled(Row)`
    margin: 2.5rem 0; /* 40px 0 */
    text-align: center;

    @media (max-width: 992px) {
        margin: 1.25rem 0;
    }
`

export const OptionRow = styled(Row)`
    @media (max-width: 992px) {
        flex-direction: column-reverse;
        align-items: center;
    }
`

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 992px) {
        margin-top: 1.25rem;
    }

    @media (max-width: 375px) {
        flex-direction: column;
    }
`

export const SectionContainer = styled(SectionLayout)`
    @media (max-width: 375px) {
        height: auto !important;
        padding: 1.25rem !important;
    }
`
