import React from 'react'

import { MapsContainer } from './googleMapsStyles'

const GoogleMaps = () => {
    const src = `https://www.google.com/maps/embed/v1/view?zoom=17&center=46.6621%2C16.8522&key=${process.env.GOOGLE_MAPS_API_KEY}`

    return (
        <MapsContainer>
            <iframe width="100%" height="100%" loading="lazy" allowFullScreen src={src} />
        </MapsContainer>
    )
}

export default GoogleMaps
