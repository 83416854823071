import React, { useContext, useState, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'

import GalleryContext from '../../contexts/gallery/gallery.context'

import Modal from 'react-bootstrap/Modal'
import Gallery from '../galleries/Gallery'
import GalleryOne from '../galleries/GalleryOne'
import GalleryTwo from '../galleries/GalleryTwo'
import GalleryThree from '../galleries/GalleryThree'
import GalleryFour from '../galleries/GalleryFour'

const componentsMap = {
    house: Gallery,
    'room-1': GalleryOne,
    'room-2': GalleryTwo,
    'room-3': GalleryThree,
    'room-4': GalleryFour,
}

const GalleryModal = () => {
    const { toggle, open, currentGallery } = useContext(GalleryContext)

    const [GalleryComponent, setGalleryComponent] = useState(null)

    useEffect(() => {
        if (isEmpty(currentGallery)) return null

        setGalleryComponent(componentsMap[currentGallery])
    }, [currentGallery])

    if (isEmpty(GalleryComponent)) return null

    return (
        <Modal show={open} onHide={toggle} fullscreen size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
            {GalleryComponent}
        </Modal>
    )
}

export default GalleryModal
