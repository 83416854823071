import React, { useContext } from 'react'
import classnames from 'classnames'

import NavbarContext from '../../contexts/navbar/navbar.context'

import { Link } from 'gatsby'
import { NavContainer, NavLink } from './mobileNavStyles'
import HeaderOptions from '../header-options/HeaderOptions'

const MobileNav = () => {
    const { hidden, toggleHidden } = useContext(NavbarContext)

    return (
        <NavContainer className={classnames('mr-auto', { open: !hidden })}>
            {/* <HeaderOptions mobileNavOpened={hidden} className="mobile-options" /> */}
            <NavLink as={Link} to="/#vendeghaz" onClick={toggleHidden}>
                vendégház
            </NavLink>
            <NavLink as={Link} to="/#szobak" onClick={toggleHidden}>
                szobák
            </NavLink>
            <NavLink as={Link} to="/#arak" onClick={toggleHidden}>
                árak
            </NavLink>
            <NavLink as={Link} to="/#galeria" onClick={toggleHidden}>
                galéria
            </NavLink>
            <NavLink as={Link} to="/#kapcsolat" onClick={toggleHidden}>
                kapcsolat
            </NavLink>
        </NavContainer>
    )
}

export default MobileNav
