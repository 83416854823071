import styled from '@emotion/styled'

export const SectionContainer = styled.section`
    position: relative;
    height: calc(100vh - 2.5rem); /* -40px */
    margin-bottom: 1.25rem; /* 20px */
    scroll-behavior: smooth;
    background-color: white;
    box-shadow: 0px 0px 15px 0px rgba(114, 111, 106, 0.16);
    overflow: hidden;

    &.p-40 {
        padding: 2.5rem; /* 40px */
    }

    &.p-60 {
        padding: 3.75rem; /* 60px */
    }

    @media (max-width: 375px) {
        padding: 1.25rem !important;
        height: auto !important;
    }
`

export const SectionHeader = styled.header`
    margin-bottom: 2.5rem; /* 40px */
    text-align: center;

    svg {
        margin-bottom: 2.5rem; /* 40px */

        .a {
            fill: var(--black);
        }
    }
`

export const Title = styled.h1`
    font-size: 3.75rem; /* 60px */
    font-family: 'Full Sans SC 70', Arial, sans-serif;
    color: var(--black);
    margin-bottom: 2.5rem; /* 40px */

    @media (max-width: 375px) {
        font-size: 1.875rem;
    }
`
