import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import GalleryContext from '../../contexts/gallery/gallery.context'

import { SectionContainer, RoomContent, RoomNumber, RoomDescription, ButtonContainer, staticImageStyle } from './roomStyles'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from '../button/Button'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import ServiceIcons from '../service-icons/ServiceIcons'

import Abstract from '../../images/svg/abstract.svg'

const Room = ({ room: { id, title, desc, services, thumbnail, gallery }, className, isTextRight, ...props }) => {
    const { toggle } = useContext(GalleryContext)

    const [image, setImage] = useState(null)

    useEffect(() => {
        if (!thumbnail) return

        const image = getImage(thumbnail)

        if (!image) return

        setImage(image)
    }, [thumbnail])

    const openGallery = () => toggle(gallery)

    return (
        <SectionContainer {...props} className={`${className} d-flex flex-column-reverse p-60`}>
            <RoomContent fluid className="align-right">
                <Row className={classnames('align-items-flex-end', { 'flex-row-reverse': isTextRight })}>
                    <Col lg="0" xl="1" />

                    <Col lg="6" xl="5" className="p-xl-0 mb-sm-4 mb-lg-0 d-flex justify-content-end flex-column">
                        {image && <GatsbyImage image={image} alt="" css={staticImageStyle} />}
                        <ButtonContainer>
                            <Button className="btn-empty color-brown btn-sm" onClick={openGallery}>
                                Galéria
                            </Button>
                            <Button as={Link} to={`/foglalas`} className="color-brown btn-sm">
                                Fogalalás
                            </Button>
                        </ButtonContainer>
                    </Col>

                    <Col lg="6" xl="5">
                        <RoomNumber className={classnames('h2 d-block', { 'text-right': !isTextRight })}>{title}</RoomNumber>
                        <RoomDescription className="text-sm-center text-lg-left">{desc}</RoomDescription>
                        <Abstract className="d-block mx-auto my-4" />
                        <ServiceIcons options={services} />
                    </Col>

                    <Col lg="0" xl="1" />
                </Row>
            </RoomContent>
        </SectionContainer>
    )
}

Room.propTypes = {
    className: PropTypes.string,
}

export default Room
