import styled from '@emotion/styled'

export const MapsContainer = styled.section`
    width: calc(100% + 2.5rem);
    height: 500px;
    margin-left: -1.25rem;

    iframe {
        border: 0;
    }
`
