import styled from '@emotion/styled'
import { css } from '@emotion/react'

const valueText = css`
    font-size: 1.25rem; /* 20px */
    color: var(--gold);
    letter-spacing: 0.05rem; /* .8px */
    margin-bottom: 0.625rem; /* 10px */

    @media (max-width: 375px) {
        font-size: 1rem;
    }
`

export const ContactLabel = styled.p`
    font-size: 1.875rem; /* 30px */
    color: var(--black);

    @media (max-width: 375px) {
        font-size: 1.25rem;
    }
`

export const ContactValue = styled.p`
    ${valueText}
`

export const ContactValueLink = styled.a`
    ${valueText}

    &:hover {
        color: var(--gold);
        opacity: 0.85;
    }
`
