import React from 'react'
import PropTypes from 'prop-types'

import { SectionContainer } from './contactSectionStyles'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ContactInfo from '../contact-info/ContactInfo'
import ContactForm from '../contact-form/ContactForm'

const ContactSection = ({ className, ...props }) => {
    return (
        <SectionContainer {...props} title="Lépj kapcsolatba velünk" className={`${className} p-60`}>
            <Container>
                <Row className>
                    <Col md="12" lg="5" className="mb-xs-4 mb-sm-5 mb-lg-0">
                        <ContactInfo />
                    </Col>
                    <Col md="12" lg={{ span: 7, offset: 0 }}>
                        <ContactForm />
                    </Col>
                </Row>
            </Container>
        </SectionContainer>
    )
}

ContactSection.propTypes = {
    className: PropTypes.string,
}

export default ContactSection
