import React, { useState, useEffect, useCallback } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import apiClient from '../../utils/apiClient'
import isEmpty from 'lodash/isEmpty'

import { FormContainer, FormCheck } from './contactFormStyles'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import Button from '../button/Button'
import FormInput from '../form-input/FormInput'
import ContactPersonalInfo from '../contact-presonal-info/ContactPersonalInfo'

const ContactForm = () => {
    const { executeRecaptcha } = useGoogleReCaptcha()

    const [token, setToken] = useState('')
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        message: '',
        gdpr: false,
    })
    const [validated, setValidated] = useState(false)
    const [notification, setNotification] = useState({
        variant: null,
        message: '',
    })

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) return

        const result = await executeRecaptcha('homepage')

        setToken(result)
    })

    useEffect(() => {
        if (!isEmpty(token)) return

        handleReCaptchaVerify()
    }, [token])

    useEffect(() => {
        if (isEmpty(notification.message)) return null

        setTimeout(() => setNotification({ variant: null, message: '' }), 1500)
    }, [notification])

    const handleInputChange = ({ target: { name, value } }) => setFormData({ ...formData, [name]: value })

    const handleCheckChange = () => setFormData({ ...formData, gdpr: !formData.gdpr })

    const handleFormSubmit = (event) => {
        event.preventDefault()
        event.stopPropagation()

        const form = event.currentTarget

        if (!form.checkValidity()) {
            setValidated(true)
            return
        }

        sendFormData({ ...formData, token })
    }

    const sendFormData = async (data) => {
        try {
            const response = await apiClient.post(`${process.env.API_URL}static/contact`, data)
            const { data: { message } } = await response

            setNotification({
                variant: 'success',
                message,
            })

            setFormData({
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                message: '',
                gdpr: false,
            })

            setValidated(false)
        }
        catch (error) {
            const { status, data: { message } } = await error.response

            setNotification({
                variant: 'danger',
                message: status === 422 ? 'A csillaggal jelölt mezők kitöltése kötelező' : message,
            })
        }
    }

    return (
        <FormContainer noValidate validated={validated} onSubmit={handleFormSubmit}>
            <ContactPersonalInfo changeHandler={handleInputChange} data={formData} />
            <Row>
                <Col>
                    <FormInput
                        as="textarea"
                        rows={4}
                        label="Üzenet *"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="gdpr">
                        <FormCheck
                            id="gdpr"
                            label={`Az "Adatvédelmi irányelvek" tájékoztatót elolvastam és elfogadom.`}
                            checked={formData.gdpr}
                            onChange={handleCheckChange}
                            required
                            feedback="A kapcsolati űrlap elküldéséhez a tájékoztatót el kell fogadnod."
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                {!isEmpty(notification.variant) && (
                    <Col>
                        <Alert variant={notification.variant}>{notification.message}</Alert>
                    </Col>
                )}
            </Row>
            <Row>
                <Col>
                    <Button className="color-brown btn-font-sm" type="submit">
                        Küldés
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className="recaptcha-info">
                        Ezt a webhelyet a reCAPTCHA és a Google{' '}
                        <a href="https://policies.google.com/privacy?hl=hu" target="_blank">
                            Adatvédelem
                        </a>{' '}
                        és{' '}
                        <a href="https://policies.google.com/terms?hl=hu" target="_blank">
                            Általános Szerződési Feltételek
                        </a>{' '}
                        védik.
                    </small>
                </Col>
            </Row>
        </FormContainer>
    )
}

export default ContactForm
