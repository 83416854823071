import styled from '@emotion/styled'

import SectionLayout from '../section-layout/SectionLayout'

export const SectionContainer = styled(SectionLayout)`
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px) {
        height: auto !important;
    }

    @media (max-width: 375px) {
        padding: 1.25rem !important;
    }
`

export const Info = styled.p`
    margin: 7.5rem auto 0 auto; /* 120px 0 0 0 */
    font-size: 0.875rem; /* 14px */
    text-align: center;
    line-height: 1.6rem;
    width: 75%;

    @media (max-width: 576px) {
        margin-top: 1.875rem;
        width: 100%;
        text-align: justify;
    }
`
