import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import ImageCarousel from '../image-carousel/ImageCarousel'

const Gallery = () => {
    const query = graphql`
        query HouseImagesQuery {
            allFile(
                filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" }, sourceInstanceName: { eq: "gallery" } }
                sort: { order: ASC, fields: name }
            ) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(width: 1920, formats: [AUTO, WEBP], placeholder: BLURRED)
                        }
                    }
                }
            }
        }
    `

    const renderCarousel = (collection) => <ImageCarousel collection={collection} />

    return <StaticQuery query={query} render={({ allFile: { edges } }) => renderCarousel(edges)} />
}

export default Gallery
