import React from 'react'

import { Circle, ScrollText, ScrollTextLetter } from './scrollDownStyles'

const ScrollDown = () => {
    return (
        <Circle to="/#vendeghaz">
            <ScrollText>
                <ScrollTextLetter className="letter-1">s</ScrollTextLetter>
                <ScrollTextLetter className="letter-2">c</ScrollTextLetter>
                <ScrollTextLetter className="letter-3">r</ScrollTextLetter>
                <ScrollTextLetter className="letter-4">o</ScrollTextLetter>
                <ScrollTextLetter className="letter-5">l</ScrollTextLetter>
                <ScrollTextLetter className="letter-6">l</ScrollTextLetter>
            </ScrollText>
            <ScrollText className="text-2">
                <ScrollTextLetter className="letter-1">s</ScrollTextLetter>
                <ScrollTextLetter className="letter-2">c</ScrollTextLetter>
                <ScrollTextLetter className="letter-3">r</ScrollTextLetter>
                <ScrollTextLetter className="letter-4">o</ScrollTextLetter>
                <ScrollTextLetter className="letter-5">l</ScrollTextLetter>
                <ScrollTextLetter className="letter-6">l</ScrollTextLetter>
            </ScrollText>
        </Circle>
    )
}

export default ScrollDown
